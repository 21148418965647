import React, { useCallback, useEffect, useState } from "react";

import "../fonts.css";

// Components
import { HeaderNav } from "./Components/HeaderNav";

// Types
import {
  EScreenMode,
  ICategory,
  IProduct,
  ICartItem,
  EMessageType,
} from "../Interfaces/generalInterfaces";

import { Popular } from "./Components/Popular";
import { FoodList } from "./Components/FoodList";
import { CartViewBottom } from "./Components/CartViewBottom";
import { FoodItemDetail } from "./Components/FoodItemDetail";
import { OrderSummary } from "./Components/OrderSumarry";
import { createClient } from "@supabase/supabase-js";
import { Categories } from "./Components/Categories";
import { Loading } from "./Components/Loading";
import { useCustomerViewAuth } from "../Auth/CustomerViewAuth";

import Dialog from "@mui/material/Dialog";

const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);

export interface IMenuPage {
  messageTrigger: (message: string, messageType: EMessageType) => void;
  setScreenMode: (screenMode: EScreenMode) => void;
}

export const Menu: React.FC<IMenuPage> = ({
  messageTrigger,
  setScreenMode,
}): JSX.Element => {
  const auth = useCustomerViewAuth();

  const [bottomModal, setBottomModal] = useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] = useState<IProduct>();

  const [selectedCategories, setSelectedCategories] = useState<
    Array<ICategory>
  >([]);
  const [carts, setCarts] = useState<Array<ICartItem>>([]);
  const [showOrderSummary, setShowOrderSummary] = useState<boolean>(false);
  const [showPopular, setShowPopular] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const account = auth.clientAccount;
  useEffect(() => {
    auth.getCategories(account.uuid);
    auth.getProducts(account.uuid);
    auth.getProductPopularity(account.uuid);
  }, []);

  useEffect(() => {
    setSelectedCategories(auth.categories);
    if (auth?.carts) {
      setCarts(auth?.carts);
    }
  }, [auth.categories]);

  const onSelectedItem = (item) => {
    setSelectedProduct(item);
    setBottomModal(true);
  };
  const handleAddToCart = (cart) => {
    const newCarts = [...carts, cart];
    setCarts(newCarts);
    setBottomModal(false);
    auth.updateCarts(newCarts);
  };
  const onSubmitOrder = () => {
    insetOrder();
  };
  const handleClose = () => {
    setBottomModal(false);
  };

  async function insetOrder() {
    const totalPrice = carts.reduce((total, item) => {
      return total + (item.product.price + item.priceAdjusting) * item.value;
    }, 0);

    const discount = () => {
      return 0;
    };
    const tax = () => {
      if (account.tax_rate > 0) {
        return totalPrice * (account.tax_rate / 100);
      } else {
        return 0;
      }
    };
    const priceAfterTax = () => {
      return totalPrice + tax();
    };
    const netTotal = () => {
      if (account.is_inclusive) {
        return totalPrice - tax();
      } else {
        return priceAfterTax();
      }
    };

    setShowLoading(true);

    const { data: order, error } = await supabase
      .from("orders")
      .insert([
        {
          order_date: new Date(),
          sub_total: totalPrice,
          account_id: account.id,
          status: "pending",
          net_total: netTotal(),
          resource_id: account.resources[0].id,
          tax_amount: tax(),
        },
      ])
      .select();
    if (error) {
      console.log(error);
    }
    if (order) {
      const orderItems = [];
      const orderItemsMessage = [];
      const productOptions = [];
      carts.forEach(function (v: ICartItem) {
        const item = {
          order_id: order[0].id,
          product_id: v.product.id,
          quantity: v.value,
          price: v.product.price,
          discount_percentage: null,
          tax_percent: null,
          discount_amount: null,
          sub_total: totalPrice,
          total: totalPrice,
          remark: v.remark,
        };
        orderItems.push(item);
        orderItemsMessage.push({
          ...item,
          priceAdjusting: v?.priceAdjusting,
          options: v?.options,
          products: v.product,
        });
        if (v.options) {
          productOptions.push({
            productId: v.product.id,
            options: v?.options,
            priceAdjusting: v.priceAdjusting,
          });
        }
      });

      const { data: orderItemResponse, error } = await supabase
        .from("order_items")
        .insert(orderItems)
        .select("*, id, products(id,name)");

      /** insert order item with varaint */
      if (orderItemResponse && orderItemResponse.length) {
        if (productOptions.length) {
          let orderOptionsInsert = [];
          let optionsDataWithValue = [];
          orderItemResponse.forEach((orderItem) => {
            const productOption = productOptions.find(
              (option) => option.productId === orderItem.product_id
            );
            if (productOption) {
              const optionsData = productOption.options.map((option) => ({
                order_item_id: orderItem.id,
                option_id: option.option_id,
                name: option.name,
              }));
              console.log(" productOption.options", productOption.options);
              const _optionsDataWithValue = productOption.options.map(
                (option) => ({
                  order_item_id: orderItem.id,
                  option_id: option.option_id,
                  name: option.name,
                  selected_values: option.selected_values,
                })
              );

              optionsDataWithValue = [
                ...optionsDataWithValue,
                ..._optionsDataWithValue,
              ];
              orderOptionsInsert = [...orderOptionsInsert, ...optionsData];
            }
          });
          /** insert to order_option*/
          const { data: orderOptionResponse, error } = await supabase
            .from("order_options")
            .insert(orderOptionsInsert)
            .select("*, options(id,name)");

          /** insert to order_option_values */

          if (orderOptionResponse) {
            const orderOptionValues = orderOptionResponse.flatMap(
              (orderOption) => {
                const selectedValues = optionsDataWithValue.find(
                  (option) =>
                    option.option_id === orderOption.option_id &&
                    option.order_item_id === orderOption.order_item_id
                )?.selected_values;

                return (
                  selectedValues?.map((value) => ({
                    order_option_id: orderOption.id, // Foreign key to `order_options`
                    option_value: value.id || null, // Option value ID (nullable)
                    name: value.name, // Option name
                    price: value.price, // Price
                  })) || []
                );
              }
            );
            const { data: orderOptionValueResponse, error } = await supabase
              .from("order_option_value")
              .insert(orderOptionValues)
              .select("*");
          }
        }
      }

      if (orderItemResponse) {
        const myOrder = { ...order[0], orderItems: orderItemsMessage };
        const myOrderToString = JSON.stringify(myOrder);
        messageTrigger(myOrderToString, EMessageType.ORDER);
        auth.updateCarts([]);
        setCarts([]);

        setScreenMode(EScreenMode.CHAT);
      }
    }
  }
  const onItemRemoved = (id: string) => {
    const newCarts = carts.filter((item) => item.id !== id);
    setCarts(newCarts);
    auth.updateCarts(newCarts);
    if (newCarts.length <= 0) {
      setShowOrderSummary(false);
    }
  };

  const handleFilterCategory = (category: ICategory) => {
    if (category.id === -1) {
      setSelectedCategories(auth.categories);
      setShowPopular(true);
    } else if (category.id === 0) {
      setShowPopular(true);
      setSelectedCategories([]);
    } else {
      setSelectedCategories([category]);
      setShowPopular(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <HeaderNav
        setScreenMode={setScreenMode}
        screenMode={EScreenMode.BACK}
        title="Menu"
        backURL={""}
      />
      <div className="flex-1">
        <div className="sticky top-0 z-10 bg-white shadow-md">
          <Categories
            categories={auth.categories}
            onfilter={(category) => handleFilterCategory(category)}
          />
        </div>
        <div className="overflow-y-auto h-full">
          {/* {showPopular && auth.popularProducts?.length > 0 && (
            <Popular
              products={auth.popularProducts}
              onSelectedItem={onSelectedItem}
            />
          )} */}
          <FoodList
            auth={auth}
            products={auth.products}
            categories={selectedCategories}
            onSelectedItem={onSelectedItem}
          />
        </div>
      </div>

      <Dialog
        maxWidth="xl"
        PaperProps={{
          style: {
            position: "fixed",
            bottom: 0,
            margin: 0,
            width: "100%",
            maxWidth: "100%",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
        onClose={handleClose}
        open={bottomModal}
      >
        <FoodItemDetail
          onAddToCart={handleAddToCart}
          product={selectedProduct}
          onClose={handleClose}
        />
      </Dialog>

      {carts.length > 0 && (
        <CartViewBottom
          carts={carts}
          onViewCart={() => setShowOrderSummary(true)}
        />
      )}
      {showOrderSummary && (
        <OrderSummary
          carts={carts}
          account={account}
          onSubmitOrder={onSubmitOrder}
          onDismiss={() => setShowOrderSummary(false)}
          onItemRemoved={onItemRemoved}
        />
      )}
      {showLoading && <Loading />}
    </div>
  );
};
