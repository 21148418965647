import React, { useEffect, useRef, useState } from "react";
import { ICategory, IProduct } from "../../Interfaces/generalInterfaces";
import { formatPrice } from "../../utils";
import _, { forEach } from "lodash";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ICustomerViewAuthContext } from "../../Auth/Interfaces";
export interface ProductContentProps {
  products: Array<IProduct>;
  categories: Array<ICategory>;
  onSelectedItem?: (item: IProduct) => void;
  onScroll?: () => void;
  auth?: ICustomerViewAuthContext;
}
export const FoodList: React.FC<ProductContentProps> = ({
  products,
  categories,
  onSelectedItem,
  onScroll,
  auth,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState("All");

  const LazyImage = ({ src, alt }) => (
    <LazyLoadImage
      src={src}
      alt={alt}
      wrapperProps={{
        style: { transitionDelay: "1s" },
      }}
      className="w-full h-auto max-w-xs"
    />
  );

  const TruncatedName = ({ text, maxLength }) => {
    const truncated =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    return truncated;
  };

  const categoryRefs = {};
  // Intersection observer callback function
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setSelectedCategory(entry.target.dataset.category);
      }
    });
  };

  useEffect(() => {
    if (selectedCategory != null) {
      categoryRefs[selectedCategory]?.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);
  forEach(categories, function (v) {
    categoryRefs[v.id] = React.createRef();
  });

  const getDisplayPriceRange = (id: string | number) => {
    auth.getDisplayPriceRange(id);
    console.log("here", id, auth.displayPriceRange);
    return auth.displayPriceRange;
  };

  return (
    <>
      {categories &&
        categories.map((category) => (
          <div
            className="mt-4"
            id={category.name}
            key={category.id}
            ref={categoryRefs[category.id]}
          >
            <div className="flex mx-5 items-center">
              <h1 className="text-2xl font-bold">{t(category.name)}</h1>
            </div>
            {products &&
              products
                .filter((product) =>
                  product.categories.some((cat) => cat.id === category.id)
                )
                .map((product) => {
                  // const displayPrice =
                  //   product.price > 0
                  //     ? formatPrice(product.price)
                  //     : getDisplayPriceRange(product.id);
                  // const displayPrice = formatPrice(product.price);
                      
                  // product.displayPrice = displayPrice;  
                  return (
                    <div
                      key={product.id}
                      onClick={() => onSelectedItem(product)}
                      className="p-5 border-b border-x-7m-bg-gray-primary"
                    >
                      <div className="flex items-center justify-between ">
                        <div className="text-left whitespace-nowrap">
                          <TruncatedName text={product.name} maxLength={25} />
                          <p>{product.displayPrice}</p>
                        </div>
                        <div className="flex-shrink-0 w-28">
                          <LazyImage
                            src={
                              product.thumbnail_url || "/images/placeholder.png"
                            }
                            alt={product.name}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        ))}
    </>
  );
};
