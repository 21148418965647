import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useLocation, Outlet, useNavigate, useParams } from "react-router-dom";
import { Popover } from "@headlessui/react";
// import Pusher from "pusher-js";
// import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";

// Utils
import NetworkManager from "../NetworkManager";

// Components
import { TopMenu } from "../User/TopMenu";

// Interfaces
import { IUser, IRestaurant, ICustomerViewAuthContext } from "./Interfaces";
import { createClient } from "@supabase/supabase-js";
import {
  IAccount,
  IBuzz,
  ICartItem,
  ICategory,
  IMessage,
  IPopularProduct,
  IProduct,
  ResourceType,
} from "../Interfaces/generalInterfaces";
import Pusher, { Channel } from "pusher-js";
import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";
import toast from "react-hot-toast";
import { CLEAR, EVENT_MESSAGE_SENT } from "../constants";
import { formatPrice } from "../utils";
const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
const AuthContext = createContext<ICustomerViewAuthContext>(null!);

export const CustomerViewAuthProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const [channel, setChannel] = useState<Channel | null>(null);

  const [messageList, setMessageList] = useState<IMessage[]>([]);

  const [accountId, setAccountId] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [isSessionClear, setIsSessionClear] = useState<boolean>(false);

  const [clientAccount, setClientAccount] = useState<IAccount>(null!);

  const [carts, setCarts] = useState<Array<ICartItem>>();
  const updateCarts = (cart: Array<ICartItem>): void => {
    setCarts(cart);
  };
  const [categories, setCategories] = useState<Array<ICategory>>(null);
  const updateCategories = (cat: Array<ICategory>): void => {
    setCategories(cat);
  };
  const [products, setProducts] = useState<Array<IProduct>>();

  const [displayPriceRange, setDisplayPriceRange] = useState<string>();

  const updateProducts = (prod: Array<IProduct>): void => {
    setProducts(prod);
  };
  const [buzz, setBuzz] = useState<Array<IBuzz>>();
  const updateBuzz = (buzz: Array<IBuzz>): void => {
    setBuzz(buzz);
  };
  const [popularProducts, setPopularProducts] =
    useState<Array<IPopularProduct>>(null);

  const updatePopularProducts = (prod: Array<IPopularProduct>): void => {
    setPopularProducts(prod);
  };
  const updateAccountId = (param: string): void => {
    setAccountId(param);
  };
  const updateResourceId = (param: string): void => {
    setResourceId(param);
  };
  const updateChannelId = (param: string): void => {
    setChannelId(param);
  };
  const updateSessionClearFlag = (channelId: string, param: boolean): void => {
    window.localStorage.setItem(CLEAR, channelId);
    setIsSessionClear(param);
  };
  const isSessionCleared = (): boolean => {
    if (
      window.localStorage.getItem(CLEAR) &&
      window.localStorage.getItem(CLEAR) === "1"
    ) {
      return true;
    }
    return false;
  };

  //need to remove hard code
  const getAccount = async (accountId: string, resourceId: string) => {
    let { data, error } = await supabase
      .from("accounts")
      .select(
        "*, resources(id, name, uuid, resource_type), banners(id, image, text, title)"
      )
      .eq("uuid", accountId)
      .eq("resources.uuid", resourceId)
      .limit(1);
    if (error) {
    }
    if (data) {
      setClientAccount(data[0]);
    }
  };
  const getBuzzList = async (accountId: string) => {
    let { data, error } = await supabase
      .from("resources")
      .select("*, accounts!inner(uuid)")
      .eq("accounts.uuid", accountId)
      .eq("resource_type", ResourceType.BUZZ)
      .limit(1);
    if (error) {
    }
    if (data && data[0].dynamic_content) {
      setBuzz(data[0].dynamic_content);
    }
  };
  const getProducts = async (accountId: string) => {
    const { data } = await supabase
      .from("products")
      .select(
        "*, categories(id,name), product_options(id, option_id, options (*, option_values(*))), accounts!inner(uuid)"
      )
      .eq("accounts.uuid", accountId)
      .not("thumbnail_url", "is", null)
      ;

    if (data) {
      // Create an array of promises for products with price 0
      const pricePromises = data.map(async (product) => {
        if (product.price === 0) {
          const { data: priceData } = await supabase.rpc(
            "get_effective_price",
            { p_id: product.id }
          );
          if (priceData) {
            product.displayPrice = priceData;
          }
        }
        else {
          product.displayPrice = formatPrice(product.price)
        }
      });

      // Wait for all promises to resolve
      await Promise.all(pricePromises);

      setProducts(data);
    }
  };

  // const getDisplayPriceRange = async (id: string | number) =>{
  //   const {data, error} = await  supabase.rpc('get_effective_price', {p_id: id});
  //   if(data ){
  //     setDisplayPriceRange(data);
  //   }
  // }

  const getProductPopularity = async (accountId: string) => {
    const { data } = await supabase
      .from("product_metrics")
      .select(
        "*, products(*, product_options(id, option_id, options (*, option_values(*)))), accounts!inner(uuid)"
      )
      .eq("accounts.uuid", accountId)
      .order("popularity", { ascending: false })
      .limit(6);
    if (data) {
      updatePopularProducts(data);
    }
  };

  const getCategories = async (accountId: string) => {
    let { data, error } = await supabase
      .from("categories")
      .select("*, accounts!inner(uuid)")
      .order("order", { ascending: true })
      .eq("accounts.uuid", accountId);
    if (data) {
      updateCategories(data);
    }
  };

  const getDisplayPriceRange = async (id: string | number) => {
    const { data, error } = await supabase.rpc("get_effective_price", {
      p_id: id,
    });
    if (data) {
      setDisplayPriceRange(data);
    }
  };

  useEffect(() => {}, []);
  const initializePusher = async (tableId, dynamicCode, restaurantId) => {
    const response = await NetworkManager.clientHandshake(
      tableId,
      dynamicCode,
      restaurantId
    );
    clientHandshake(response);
    if (!restaurantId || !tableId || !response.payload.channelId) {
      //  return setComponentError('Error in table identifiers');
    }

    const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
      authorizer: (channel) =>
        NetworkManager.pusherAuthorizer(channel, accountId, resourceId),
      cluster: window._env_.PUSHER_CLUSTER,
    });

    setPusher(pusher);

    const localChannel = pusher.subscribe(
      response.payload.channelId
    ) as PrivateChannel;
    setChannel(localChannel);
  };

  const clientHandshake = async (response: any) => {};

  const handleMessageSent = useCallback(
    (data: IMessage) => {
      setMessageList((prevState) => [...prevState, data]);
    },
    [messageList]
  );

  const value = {
    accountId,
    updateAccountId,
    resourceId,
    updateResourceId,
    channelId,
    updateChannelId,
    clientAccount,
    carts,
    updateCarts,
    categories,
    updateCategories,
    products,
    updateProducts,
    buzz,
    updateBuzz,
    popularProducts,
    updatePopularProducts,
    getAccount,
    getBuzzList,
    pusher,
    initializePusher,
    channel,
    getProducts,
    getCategories,
    getProductPopularity,
    isSessionClear,
    updateSessionClearFlag,
    isSessionCleared,
    getDisplayPriceRange,
    displayPriceRange,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useCustomerViewAuth = () => useContext(AuthContext);

function toastConfig(
  arg0: boolean
): Partial<
  Pick<
    import("react-hot-toast").Toast,
    | "id"
    | "icon"
    | "duration"
    | "ariaProps"
    | "className"
    | "style"
    | "position"
    | "iconTheme"
  >
> {
  throw new Error("Function not implemented.");
}
